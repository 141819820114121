@media screen and (max-width: 768px) {
    .fc-header-toolbar button.fc-button {
        padding: 0.2em 0.45em;
        font-size: 12px;
    }
    .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
        margin-left: 0.5em;
    }
    .fc .fc-col-header-cell-cushion {
        font-size: 13px;
    }
}

/* calendar */
.fc .fc-timegrid-col.fc-day-today.bg-green-300 {
    background-color: rgb(187 247 208)!important;
}


.fc-toolbar-chunk .fc-toolbar-title {
    font-size: 16px;
    font-weight: 600;
}
.fc-toolbar-chunk {
    display: flex;
    align-items: center;
}
@media screen and (max-width: 768px) {
    .fc-toolbar-chunk {
        display: block
    }
    .fc-toolbar-chunk .fc-toolbar-title {
        margin-left: 0 !important;
        margin-bottom: -20px;
        margin-top: 8px;
    }
}